import React from 'react'

const ProductTechDetails = () => {
  return (
    <div className="container" style={{ maxWidth: "100%" }} dir="ltr">
      <p className="p-2 text-success fw-bold bg-success-subtle border border-success-subtle rounded-3">
        TECHNICAL DETAILS
      </p>

      <div className="row">
        <div >
          <div>
            <div>

              <table className="table">
                <tbody>
                  <tr>
                    <td className="fw-bold">
                      Rated usable power
                    </td>
                    <td>Starting from 2 MW, scalable</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">
                      Usable energy capacity
                    </td>
                    <td>Starting from 3 MWh, scalable</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">
                      Product delivery form
                    </td>
                    <td>Standard 20/40 ft container(s)</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">
                      Design life of BESS system
                    </td>
                    <td>15 years whole system, battery swappable</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">
                      Battery type
                    </td>
                    <td>Lithium-ion LPF type</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">
                      Grid connection
                    </td>
                    <td>Medium voltage grid via trafo (10/20/33 kV)</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Solution range</td>
                    <td>
                      Complete solution with battery, power conversion, switch gear,
                      sub-station, etc.
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">
                      Applications
                    </td>
                    <td>Energy arbitrage, FCR, aFRR, mFRR, capacity market, etc.</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">
                      Scope of work
                    </td>
                    <td>In-house: Design, engineering, FAT, SAT, project management
                      Sub-contract: transportation on-site, installation, construction, energy
                      trading, asset management, etc.</td>
                  </tr>

                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductTechDetails